// 会员
<template>
  <div class="about">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <div>
        <el-form :inline="true">
          <!-- <el-form-item label="客户端类型:" required>
              <el-select v-model="clientId" placeholder="请选择">
                <el-option
                  v-for="(item,i) in channelList "
                  :key="i"
                  :label="item.description"
                  :value="item.clientId"
                ></el-option>
              </el-select>
            </el-form-item>
              <el-form-item required>
                <el-button
                  type="primary ml10"
                  style="marginLeft: 10px"
                  icon="el-icon-search"
                  @click="handleClick({index:1})"
                >搜索</el-button>
          </el-form-item>-->
          <el-button type="primary" @click="addMember">新建支付产品</el-button>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="metadata.clientId" label="产品ID" width="120" align="center">
          <template #default="scope">
            <span>{{ scope.row.metadata && scope.row.metadata.clientId }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="clientType" label="客户端类型" align="center">
          <template #default="scope">
            <span>{{ scope.row.metadata && scope.row.metadata.clientType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="channel" label="渠道" align="center">
          <template #default="scope">
            <span>{{ scope.row.airwallexPrice && scope.row.airwallexPrice[0]['metadata'] && scope.row.airwallexPrice[0]['metadata'].channel }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="airwallexPrice" label="产品类型" align="center">
          <template #default="scope">
            <span>{{ scope.row.airwallexPrice ? scope.row.airwallexPrice[0].type : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="unit_amount" label="价格" align="center" width="80">
          <template #default="scope">
            <span>{{ scope.row.airwallexPrice && scope.row.airwallexPrice[0]['unit_amount'] }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="产品名称" align="center"></el-table-column>
        <el-table-column prop="description" label="产品描述" align="center"></el-table-column>
        <el-table-column prop="payType" label="支付方式" align="center"></el-table-column>

        <el-table-column prop="period_unit" label="订阅周期" align="center" width="80">
          <template #default="scope">
            <span>{{ scope.row.airwallexPrice && scope.row.airwallexPrice[0]['type'] == "RECURRING" && typeFilter(scope.row.airwallexPrice[0].recurring.period_unit) || ''}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="dailyTime" label="试用周期" align="center" width="80">
          <template #default="scope">
            <span>{{ scope.row.airwallexPrice && scope.row.airwallexPrice[0]['type'] == "RECURRING" && scope.row.airwallexPrice[0].metadata.trialDays || ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dailyTime" label="试用价格" align="center" width="80">
          <template #default="scope">
            <span>{{ scope.row.airwallexPrice && scope.row.airwallexPrice[0]['type'] == "RECURRING" && scope.row.airwallexPrice[0].metadata.trialAmount || ''}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="active" label="状态" align="center" width="80">
          <template #default="scope">
            <span>{{ scope.row.active? '启用':'禁用' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              memberDialog
              @click="memberEdit(scope.$index, scope.row)"
            >修改</el-button>
            <el-button
              type="text"
              icon="el-icon-circle-close"
              v-if="scope.row.active"
              class="red"
              @click="changeMemberStatus(scope.row)"
            >禁用</el-button>
            <el-button
              type="text"
              icon="el-icon-circle-check"
              v-else
              @click="changeMemberStatus( scope.row)"
            >启用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          background
          layout=" prev, pager, next"
          :current-page="pageIndex"
          :page-size="20"
          :total="total"
          @current-change="pageChange"
        ></el-pagination>
      </div>
      <!-- 会员弹窗 -->
      <el-dialog v-model="memberDialog" class="jf-main" :title="memberTitle" width="40%" center>
        <el-form ref="formData" label-width="140px">
          <el-form-item label="客户端类型:" required>
            <el-input v-model.trim="memberItem.clientType"></el-input>
          </el-form-item>
          <el-form-item label="客户端Id:" required>
            <el-input v-model.trim="memberItem.clientId" :disabled="memberType == 'edit'"></el-input>
          </el-form-item>
          <el-form-item label="产品类型:" required>
            <el-select v-model="memberItem.type" placeholder="请选择" :disabled="memberType == 'edit'">
              <el-option v-for="(item,i) in types" :key="i" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="价格:" required>
            <el-input
              v-model.trim="memberItem.unit_amount"
              type="number"
              :disabled="memberType == 'edit'"
              @input="checkNum"
              maxlength="100"
            ></el-input>
          </el-form-item>
          <el-form-item label="渠道:" required>
            <el-input v-model.trim="memberItem.channel" type="text" maxlength="100"></el-input>
          </el-form-item>

          <el-form-item label="产品名称:" required>
            <el-input v-model.trim="memberItem.name" type="text" maxlength="100"></el-input>
          </el-form-item>

          <el-form-item label="产品描述:" required>
            <el-input v-model.trim="memberItem.description" maxlength="32"></el-input>
          </el-form-item>
          <el-form-item label="支付方式:" required>
            <el-checkbox-group v-model="memberItem.payType">
              <el-checkbox label="airwallex">airwallex</el-checkbox>
              <el-checkbox label="paypal">paypal</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="订阅周期:" required v-if="memberItem.type == 'RECURRING'">
            <el-select
              v-model="memberItem.period_unit"
              placeholder="请选择"
              :disabled="memberType == 'edit'"
            >
              <el-option
                v-for="(item,i) in subscribeArr"
                :key="i"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="循环周期:" required class="zhouqi" v-if="memberItem.type == 'RECURRING'">
            <el-input
              v-model.trim="memberItem.period"
              type="Number"
              width="200px"
              :disabled="memberType == 'edit'"
            ></el-input>
            <span>{{typeFilter(memberItem.period_unit)}}</span>
          </el-form-item>

          <el-form-item label="试用周期:" class="zhouqi" v-if="memberItem.type == 'RECURRING'">
            <el-input v-model.trim="memberItem.trialDays" type="Number" width="200px"></el-input>
            <span>天</span>
          </el-form-item>
          <el-form-item label="试用价格:" class="zhouqi" v-if="memberItem.type == 'RECURRING'">
            <el-input v-model.trim="memberItem.trialAmount" type="Number" width="200px"></el-input>
          </el-form-item>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="saveMemberDialog">保存</el-button>
            <el-button type="primary" @click="memberDialog = false">取消</el-button>
          </span>
        </template>
      </el-dialog>
    </el-tabs>
  </div>
</template>
<script>
import {
  getAllProducts,
  modifyProduct,
  getMemberList,
  operateProduct,
  createProduct,
  clientConfig
} from "../../api/index";
var md5 = require("../../utils/md5.js");

export default {
  name: "pay-product",
  data() {
    return {
      clientId: "",
      activeName: "first",
      tableData: [],
      showDialog: false,
      memberDialog: false,
      memberType: "",
      row: {
        recommend: false
      },
      memberList: [],
      total: 0,
      pageIndex: 1,
      memberItem: {
        payType: ["airwallex"]
      },
      jfTitle: "添加积分套餐",
      memberTitle: "新建支付产品",
      days: [],
      limitDialog: false, // 分类
      tags: [],
      labelList: [], // 分类
      channelList: "", //渠道列表
      types: [
        {
          value: "ONE_TIME",
          name: "一次性支付"
        },
        {
          value: "RECURRING",
          name: "订阅"
        }
      ],
      subscribeArr: [
        {
          value: "DAY",
          name: "天"
        },
        {
          value: "WEEK",
          name: "周"
        },
        {
          value: "MONTH",
          name: "月"
        },
        {
          value: "YEAR",
          name: "年"
        }
      ],
      cycleArr: [{}]
    };
  },
  async mounted() {
    await this.queryClientConfig();
    this.getList();
  },

  methods: {
    typeFilter(val) {
      let str = "";
      this.subscribeArr.forEach(element => {
        if (element.value == val) {
          str = element.name;
        }
      });
      console.log("str", str);
      return str || "";
    },
    copyFn() {
      var cInput = document.createElement("input");
      cInput.value = '[{"name":"koutu","value":20},{"name":"tool","value":30}]';
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");

      this.$message({
        type: "success",
        message: "复制成功"
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    clientIdFilter(val) {
      let str = "";
      this.channelList.forEach(element => {
        if (element.clientId == val) {
          str = element.description;
        }
      });
      return str + " " + val;
    },
    checkNum(e) {
      console.log("e", e);
      if (e) {
        let _e = e.toString();
        const [a, b = null] = _e.split(".");
        console.log("vbbb", b);
        console.log("a", a);
        if (b && b.length > 2) {
          this.$notify.warning({
            title: "提示",
            message: "价格请输入两位有效小数"
          });
          return false;
        } else {
          return true;
        }
      }
    },
    queryClientConfig() {
      clientConfig().then(res => {
        if (res.code == 0) {
          this.channelList = res.data;
        }
        console.log("res", res);
      });
    },
    handleClick(tab) {
      this.tabIndex = tab.index;
      if (tab.index == 0) {
        this.getList();
      } else {
        this.getMemberData();
      }
    },
    pageChange(v) {
      this.pageIndex = v;
      this.getList();
      console.log("当前页", v);
    },
    // 获取积分列表
    getList() {
      let env = "dev";
      if (process.env.NODE_ENV === "production") {
        env = "prod";
      }
      let params = {
        pageNum: this.pageIndex,
        pageSize: 20,
        env
      };
      let key = "5e776136251349edafab59af837c031e";
      let str = `${key}${params.env}${params.pageNum}${params.pageSize}`;
      let sign = md5(str).toUpperCase();
      params.sign = sign;
      getAllProducts(params).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.items;
          this.total = res.data.total_size;
        }
        console.log("this.tableData", this.tableData);
      });
    },
    //
    handleEdit(index, row) {
      this.jfTitle = "修改积分套餐";
      console.log(index);
      this.row = row;
      this.showDialog = true;
    },
    // 加密参数转化
    parseSignStr(_obj) {
      let obj = _obj;
      // 获取对象的属性名数组
      var keys = Object.keys(obj);

      // 对属性名数组进行排序
      keys.sort();
      let str = "";
      // 根据排序后的属性名数组访问对象的属性
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        var value = obj[key];
        str += value;
      }
      return str;
    },
    // vip会员保存
    saveMemberDialog() {
      if (!this.memberItem.clientType.trim()) {
        return this.$notify.warning({
          title: "提示",
          message: "请输入客户端类型"
        });
      }
      if (!this.memberItem.clientId.trim()) {
        return this.$notify.warning({
          title: "提示",
          message: "请输入客户端Id"
        });
      }
      if (!this.memberItem.type.trim()) {
        return this.$notify.warning({
          title: "提示",
          message: "请选择产品类型"
        });
      }
      if (!this.memberItem.unit_amount) {
        return this.$notify.warning({
          title: "提示",
          message: "请输入价格"
        });
      }
      if (!this.checkNum(this.memberItem.unit_amount)) {
        return false;
      }
      if (!this.memberItem.channel) {
        return this.$notify.warning({
          title: "提示",
          message: "请输入渠道"
        });
      }

      if (!this.memberItem.name) {
        return this.$notify.warning({
          title: "提示",
          message: "请输入产品名称"
        });
      }

      if (!this.memberItem.description) {
        return this.$notify.warning({
          title: "提示",
          message: "请输入产品描述"
        });
      }

      if (this.memberItem.type == "RECURRING" && !this.memberItem.period_unit) {
        return this.$notify.warning({
          title: "提示",
          message: "请选择订阅周期"
        });
      }
      if (this.memberItem.type == "RECURRING" && !this.memberItem.period) {
        return this.$notify.warning({
          title: "提示",
          message: "请输入循环周期"
        });
      }
      if (this.memberItem.payType.length < 1) {
        return this.$notify.warning({
          title: "提示",
          message: "请选择支付方式"
        });
      }
      let params = {
        ...this.memberItem,
        pricing_model: "per_unit"
      };
      if (this.memberItem.payType.length == 2) {
        console.log(1);
        params.payType = "default";
      } else {
        params.payType = params.payType[0];
      }

      delete params.period_unit;
      delete params.period;

      // 订阅
      if (this.memberItem.type == "RECURRING") {
        params.period_unit = this.memberItem.period_unit;
        params.period = this.memberItem.period;
      }

      console.log("params", params);
      let key = "5e776136251349edafab59af837c031e";
      // "paramString": "5e776136251349edafab59af837c031e222332323default234DAYper_unit24324RECURRING232",
      //   "paramNameString": "channel clientid clienttype description name paytype period period_unit pricing_model trialamount trialdays type unit_amount",
      let str = null;
      if (this.memberType == "edit") {
        // {"clientType":"12",
        // "clientId":"31","name":"13","description":"13",
        // "unit_amount":"132","channel":"13","trialDays":"13",
        // "trialAmount":"1","pricing_model":"per_unit",
        // "period_unit":"WEEK","period":"1","sign":"C6E3A945D252193AD2854546FA7F1198"}
        // channel clientid clienttype description id name period period_unit price_id pricing_modeltrialamounttrialdaysunit_amount
        console.log("params.channel ", params.channel);
        str = `${key}${params.channel || ""}${params.clientId ||
          ""}${params.clientType || ""}${params.description || ""}${
          params.id
        }${params.name || ""}${params.period || ""}${params.period_unit ||
          ""}${params.price_id || ""}${params.pricing_model || ""}${
          params.trialAmount
        }${params.trialDays}${params.unit_amount || ""}`;
        delete params.metadata;
        delete params.airwallexPrice;
        delete params.active;
        delete params.metadata;
        delete params.unit;
      } else {
        str = `${key}${params.channel || ""}${params.clientId ||
          ""}${params.clientType || ""}${params.description ||
          ""}${params.name || ""}${params.period || ""}${params.period_unit ||
          ""}${params.pricing_model || ""}${params.trialAmount ||
          ""}${params.trialDays || ""}${params.unit_amount || ""}`;
      }
      // price_id
      console.log("str", str);
      let sign = md5(str).toUpperCase();
      params.sign = sign;

      delete params.request_id;
      delete params.payType;
      delete params.type;
      // 校验结束
      if (this.memberType == "edit") {
        modifyProduct(params).then(res => {
          if (res.code == 0) {
            this.memberDialog = false;
            this.getList();
            this.$notify.success({
              title: "提示",
              message: "数据编辑成功"
            });
          } else {
            this.$notify.warning({
              title: "提示",
              message: res.message
            });
          }
        });
      } else {
        createProduct(params).then(res => {
          if (res.code == 0) {
            this.memberDialog = false;
            this.getList();
            this.$notify.success({
              title: "提示",
              message: "数据添加成功"
            });
          } else {
            this.$notify.warning({
              title: "提示",
              message: res.message
            });
          }
        });
      }
    },
    changeMemberStatus(row) {
      let str = row.active ? "禁用" : "启用";
      this.$confirm("确定要" + str + "吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          let env = "dev";
          if (process.env.NODE_ENV === "production") {
            env = "prod";
          }
          let params = {
            id: row.id,
            env,
            active: !row.active
          };
          let key = "5e776136251349edafab59af837c031e";
          let str = `${key}${params.active}${params.env}${params.id}`;
          let sign = md5(str).toUpperCase();
          params.sign = sign;

          operateProduct(params).then(res => {
            console.log(res);
            this.getList();
            this.$notify.success({
              title: "提示",
              message: "操作成功"
            });
          });
        })
        .catch(() => {});
    },

    getMemberData() {
      let params = {
        name: "",
        comboName: "",
        pageIndex: 1,
        pageSize: 100,
        clientId: this.clientId
      };
      getMemberList(params).then(res => {
        console.log(res);
        this.memberList = res.data.data;
      });
    },
    addMember() {
      //   this.memberTitle = "修改支付产品";
      this.memberItem = {
        clientType: "", //客户端类型
        clientId: "", //客户端Id
        type: "", //产品类型
        name: "", //产品名称
        description: "", //产品描述
        payType: ["airwallex"], //支付方式
        period_unit: "" //订阅周期
      };
      this.memberType = "add";
      this.memberTitle = "新建支付产品";
      this.memberDialog = true;
    },
    memberEdit(index, row) {
      this.memberType = "edit";
      this.memberTitle = "修改支付产品";
      console.log("obj", row);
      let payType = [];
      if (row.payType == "default") {
        payType = ["airwallex", "paypal"];
      } else {
        if (row.payType) {
          payType = [row.payType];
        }
      }

      this.memberItem = {
        ...row,
        clientId: row?.metadata?.clientId || "",
        clientType: row?.metadata?.clientType || "",
        payType
      };
      if (row.airwallexPrice && row.airwallexPrice.length > 0) {
        const airwallexPrice = row.airwallexPrice[0];
        this.memberItem.channel = airwallexPrice?.metadata?.channel;
        this.memberItem.type = airwallexPrice?.type;
        this.memberItem.unit_amount = airwallexPrice?.unit_amount;
        if (this.memberItem.type == "RECURRING") {
          // 试用期
          this.memberItem.trialAmount = airwallexPrice?.metadata?.trialAmount;
          this.memberItem.trialDays = airwallexPrice?.metadata?.trialDays;

          this.memberItem.period = airwallexPrice?.recurring?.period;
          this.memberItem.period_unit = airwallexPrice?.recurring?.period_unit;
        }
        this.memberItem.price_id = airwallexPrice["id"];
      }
      this.memberDialog = true;
    }
  }
};
</script>
<style lang="scss">
.jf-main .up-btn {
  display: inline-block;
  vertical-align: middle;
}
.jf-main .up-btn .btn-text {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #fff;
  line-height: 32px;
}

.up-btn ::v-deep .el-upload {
  width: 98px;
  height: 40px;
  border: 0 !important;
  background-color: #409eff;
  line-height: 20px;
}
.up-btn >>> .el-button--small {
  width: 80px;
  height: 32px;
  border: 0 !important;
  background-color: #409eff;
}
.pre {
  width: 100px;
  height: 100px;
}
.up-btn .el-upload--text {
  border: 0;
  width: 100px;
  height: 40px;
}
.limit {
  margin-left: 10px;
}
.limit-top {
  display: flex;
  justify-content: center;
  .el-input {
    width: 220px;
  }
  .limit-btn {
    margin-left: 10px;
  }
}
.label-box {
  list-style: none;
  margin-top: 20px;
  .label {
    line-height: 40px;
    background: #eee;
    text-align: center;
    margin-bottom: 6px;
    cursor: pointer;
    &:hover {
      background-color: #ccc;
    }
  }
}
.tag {
  margin-right: 4px;
  margin-bottom: 4px;
}
.tag-list {
  display: inline-block;
}
.red {
  color: red;
}
.flex {
  display: flex;
  align-items: center;
}
.zhouqi {
  .el-input {
    width: 120px;
    margin-right: 10px;
  }
}
</style>